<template>
	<div v-if="authed">
		<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
			:options.sync="options" :server-items-length="totalItems"
			:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header
			:loading="loading" loading-text="加载中...">
			<template v-slot:top>
				<div class="d-flex flex-wrap">
					<v-btn-toggle mandatory dense v-model="filter" @change="fetchData">
						<v-btn>待授权</v-btn>
						<v-btn>已出结果</v-btn>
					</v-btn-toggle>
					<v-text-field placeholder="姓名" v-model.trim="pname" dense clearable hide-details style="flex: 0 1 auto" class="ml-4"
						append-icon="search" @click:append="fetchData" @keyup.enter.native="fetchData"/>
				</div>
				<v-divider/>
			</template>
			<template v-slot:item.actions="props">
				<div style="display:inline-flex">
					<v-edit-dialog v-if="canEdit" large save-text="保存" cancel-text="取消" @save="saveFile(props.item)">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon v-bind="attrs" v-on="on"><v-icon>upload_file</v-icon></v-btn>
							</template>
							<span>上传</span>
						</v-tooltip>
						<template v-slot:input>
							<v-file-input small-chips placeholder="选取文件" label="计费单" accept=".xls,.xlsx,.doc,.docx,.pdf,.zip,.rar"
								style="width:300px" v-model="preAuthFile" @change="onFileChange"/>
						</template>
					</v-edit-dialog>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on" :disabled="!props.item.preAuthFile" :href="props.item.preAuthFile"><v-icon>save_alt</v-icon></v-btn>
						</template>
						<span>下载</span>
					</v-tooltip>
				</div>
			</template>
			<template v-slot:item.age="{ item }">
				{{getAge(item)}}
			</template>
			<template v-slot:item.opts="{ item }">
				{{getOptsStr(item)}}
			</template>
			<template v-slot:item.insurance="{ item }">
				<span :class="item.payment.insurance ? 'teal--text text--accent-4 font-weight-bold' : 'black--text'">{{getInsurance(item)}}</span>
			</template>
			<template v-slot:item.preAuthResult="props">
				<v-edit-dialog v-if="canEdit" large @open="preAuthResult=null" save-text="保存" cancel-text="返回" @save="preAuthorize(props.item)">
					<span class="text-decoration-underline" style="color:#03A9F4;">预授权</span>
					<span v-if="props.item.preAuthResult"> (已{{props.item.preAuthResult}})</span>
					<template v-slot:input>
						<div style="text-align:center;padding:12px;">
							<v-radio-group v-model="preAuthResult" hide-details>
								<v-radio label="通过" value="通过"/>
								<v-radio label="拒绝" value="拒绝"/>
							</v-radio-group>
						</div>
					</template>
				</v-edit-dialog>
				<template v-else>
					{{props.item.preAuthResult || '等待预授权'}}
				</template>
			</template>
			<template v-slot:item.progress.preAuthorized="props">
				{{formatTime(props.item.progress.preAuthorized)}}
			</template>
			<template v-slot:item.progress.order="{ item }">
				{{formatTime(item.progress.order)}}
			</template>
		</v-data-table>
	</div>
	<div v-else>
		无此权限
	</div>
</template>

<script>
	import {formatTime, calculateAge} from '../utils'

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'计费单', value:'actions', width:100, sortable: false},
                    {text:'病案号', value:'pid', width:80},
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'支付方', value:'insurance', width:150},
                    {text:'预授权结果', value:'preAuthResult', width:180},
                    {text:'出结果时间', value:'progress.preAuthorized', width:180},
                    {text:'下单时间', value:'progress.order', width:180},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'年龄', value:'age', width:80},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'出生日期', value:'pii.dob', width:120},
                    {text:'证件类型', value:'pii.idtype', width:100},
                    {text:'证件号', value:'pii.idcard', width:120},
                    {text:'套餐内容', value:'opts', width:240, sortable: false},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'订单号', value:'_id', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				filter: 0,
				options: {},
				totalItems: 0,
				pname: '',
				canEdit: false,
				preAuthFile: null,
				preAuthResult: null,
            }
        },
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
			}
		},
		created() {
			this.formatTime = formatTime;
		},
        mounted() {
			this.authed = this.$hasPrivilege(['商务', '保险预授权-只读', '健康顾问']);
			if (!this.authed) return;
			this.canEdit = this.$hasPrivilege('商务');
			this.fetchData();
		},
        methods: {
            getAge(item) {
                return calculateAge(item.pii.dob, item.progress.order);
            },
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO + (item.opts.TX ? '/' + item.opts.TX : '');
			},
			getInsurance(item) {
				return item.payment.insurance && item.payment.insurer ? `${item.payment.insurer} - ${item.payment.insurance}` : '自费';
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const filters = [
					{'progress.preAuthorized':_.exists(false)},
					{'progress.preAuthorized':_.exists(true)}
				];
				let filter = [{"payment.insurance":'直付'}];
				if (this.$hasPrivilege(['商务'])) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					this.consultantId = u.consultantId;
					filter.push({consultantId: u.consultantId});
				}
				if (this.pname) {
					filter.push({'pii.username':this.pname});
				}
				filter.push({hide:_.neq(true)});
				const f = filter.concat(filters[this.filter]);
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async preAuthorize(item) {
				if (!this.preAuthResult) {
					this.$dialog.message.error('请选择预授权结果');
					return;
				}
				this.loading = true;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname: 'preAuthorize',
							data: {
								id: item._id,
								result: this.preAuthResult,
							}
						}
					});
					this.fetchData();
				} catch(err) {
					console.error(err);				
				}
				this.loading = false;
			},
			onFileChange(e) {
				if (e) {
					this.preAuthFile = e;
				} else {
					this.preAuthFile = null;
				}
			},
			async saveFile(item) {
				this.loading = true;
				try {
					if (this.preAuthFile) {
						const ext = this.preAuthFile.name.split('.').pop();
						const filename = 'preauth/' + item._id + '.' + ext;
						const rf = await this.$tcbapp.uploadFile({
							config: {env: 'huasan-6gaadaha7ceb62a9'},
							cloudPath: filename,
							filePath: this.preAuthFile
						});
						await this.$tcbapp.callFunction({name:"wp2mp",
							data:{
								funcname:'savePreAuthFile',
								data: {
									id: item._id,
									cloudfile: rf,
								}
							}
						});
						item.preAuthFile = rf.download_url;
					} else {
						await this.$tcbapp.callFunction({name:"wp2mp",
							data:{
								funcname:'savePreAuthFile',
								data: {
									id: item._id,
									cloudfile: null,
								}
							}
						});
						item.preAuthFile = null;
					}
					this.$dialog.message.success('保存完成');
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('保存失败');
				}
				this.loading = false;
			},
		},
    }
</script>
